// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/builds/YfQWJsug/0/group-hk/llm-partnerportal/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/404",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index__index' */'@/pages/index/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/login",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__login__index' */'@/pages/login/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/records",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__records__index' */'@/pages/records/index.tsx'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})]
      },
      {
        "path": "/register",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__register__index' */'@/pages/register/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/terms-of-use",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__terms-of-use__index' */'@/pages/terms-of-use/index.tsx'), loading: LoadingComponent})
      },
      {
        "path": "/wallet",
        "exact": true,
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__wallet__index' */'@/pages/wallet/index.tsx'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})]
      },
      {
        "path": "/developers",
        "routes": [
          {
            "path": "/developers",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developers__index' */'@/pages/developers/index.tsx'), loading: LoadingComponent})
          },
          {
            "path": "/developers/webhooks",
            "exact": true,
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developers__webhooks__index' */'@/pages/developers/webhooks/index.tsx'), loading: LoadingComponent})
          }
        ],
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__developers___layout' */'@/pages/developers/_layout.tsx'), loading: LoadingComponent}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/wrappers/auth'), loading: LoadingComponent})]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404.tsx'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
